import React from "react";
import { Box, Grid, Stack, IconButton, Typography } from "@mui/material";
import useclasses from "./footerStyle";
import clsx from "clsx";

export default function FooterNew() {
  const classes = useclasses();
  return (
    <Box>
      <Box  className={clsx(classes.footerSection,"section-container")}>
        <Grid container className={clsx(classes.footerContent,"page-width")}>
          <Grid item xs={12} sm={8} className={classes.footerGrid}>
            <Typography className={classes.copyRight}>Copyright © 2023 ZM Polymers Pvt. Ltd.</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.footerGrid}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: "center", md: "flex-end",gap:'20px' }}
              className={{ paddingTop: { xs: "20px", sm: "0" }  }}
            >
              <IconButton
                aria-label="whatsApp"
                component="a"
                href="https://wa.me/9738034444"
                className={classes.iconBackColor}
              >
                <img src='/social/whatsapp.png' alt="ZM WhatsApp Number Social Media Icon"/>
                {/* <WhatsAppIcon className={classes.iconColor1} /> */}
              </IconButton>
              <IconButton
                aria-label="email"
                component="a"
                href="mailto:z.m.polymers66@gmail.com"
                className={classes.iconBackColor}
              >
                {/* <EmailIcon className={classes.iconColor3} /> */}
                <img src='/social/gmail.png' alt="ZM Email Social Media Icon"/>
              </IconButton>
              <IconButton
                aria-label="phone"
                component="a"
                href="tel:97380 34444"
                className={classes.iconBackColor}
              >
                {/* <PhoneIcon className={classes.iconColor2} /> */}
                <img src='/social/telephoneCall.png' alt="ZM Calling Number Social Media Icon"/>
              </IconButton>
             
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
