import React from "react";
import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useclasses from "./HeaderStyle";

export default function FinalHeaderTopBar() {
  const classes = useclasses();
  return (
    <>
      <Hidden only={["xs", "sm"]}>
        <Box className={classes.headerTopBG}>
          <Box className="page-width">
            <Grid container className={classes.mainGrid}>
              <Grid item  className={classes.gridTop}>
                <ListItem
                  disablePadding
                  className={classes.headerItem}
                  component="a"
                  href="tel:9738034444"
                >
                  <ListItemIcon className={classes.leftlistIcons}>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listText}>
                    Phone : +91 97380 34444
                  </ListItemText>
                </ListItem>
              </Grid>

              <Grid item >
                <ListItem
                  disablePadding
                  className={classes.headerItem}
                  component="a"
                  href="https://wa.me/9738034444"
                >
                  <ListItemIcon className={classes.leftlistIcons}>
                    <WhatsAppIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listText}>
                    WhatsApp : +91 97380 34444
                  </ListItemText>
                </ListItem>
              </Grid>

              <Grid item  >
                <ListItem
                  disablePadding
                  className={classes.headerItem}
                  component="a"
                  href="mailto:z.m.polymers66@gmail.com"
                >
                  <ListItemIcon className={classes.leftlistIcons}>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listText}>
                    Mail : z.m.polymers66@gmail.com
                  </ListItemText>
                </ListItem>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>
    </>
  );
}
