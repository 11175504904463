const images = [
    {
        imgPath: "./images/Banners/Easy_Grip_Ms_Self_Tapping_Screw.webp",
        title:'Easy Grip Ms Self Tapping Screw'
    },
    {
        imgPath: "./images/Banners/SM_Aluminium_Extrusion.webp",
        title:'SM Aluminium Extrusion'
    },
    {
        imgPath: "./images/Banners/Perfect_PVC_Rubber_Profile.webp",
        title:'Perfect PVC Rubber Profile'
    },
];

export default images;