import './App.css';
import Router from './Router';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';


function App() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <Router />
      </ThemeProvider>
    </>
  );
}

export default App;
