import React, { useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Button,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CallIcon from "@mui/icons-material/Call";
import axios from "axios";
import useclasses from "./contactUsStyle";
import Flip from "react-reveal/Flip";

const ContactUs = () => {
  const classes = useclasses();
  const [loading, setLoading] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isMobileNoValid, setIsMobileNoValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [inputFields, setInputFields] = useState({
    firstname: "",
    lastname: "",
    email: "",
    subject: "Aluminium",
    city: "",
    mobileNo: "",
    message: "",
  });

  const inputHandler = (e) => {
    if (e.target.name === "mobileNo") {
      var number = e.target.value;
      const regex = /^[0-9\b]+$/;
      if (number === "" || regex.test(number)) {
        setInputFields((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        var temp = inputFields;
        temp[e.target.name] = e.target.value;
        checkValidation(temp);
      }
    } else {
      setInputFields((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      var temp = inputFields;
      temp[e.target.name] = e.target.value;
      checkValidation(temp);
    }
  };

  const checkValidation = (values) => {
    if (isSubmitted) {
      var validateFirstName =
        values.firstname === ""
          ? setIsFirstNameValid(false)
          : setIsFirstNameValid(true);
      var validateLastName =
        values.lastname === ""
          ? setIsLastNameValid(false)
          : setIsLastNameValid(true);
      var validateMobileNo =
        values.mobileNo.length === 10 || values.mobileNo.length === undefined
          ? setIsMobileNoValid(true)
          : setIsMobileNoValid(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    // checkValidation();
    var validateFirstName =
      inputFields.firstname === ""
        ? setIsFirstNameValid(false)
        : setIsFirstNameValid(true);
    var validateLastName =
      inputFields.lastname === ""
        ? setIsLastNameValid(false)
        : setIsLastNameValid(true);
    var validateMobileNo =
      inputFields.mobileNo.length === 10 ||
      inputFields.mobileNo.length === undefined
        ? setIsMobileNoValid(true)
        : setIsMobileNoValid(false);

    if (isSubmitted && isFirstNameValid && isLastNameValid && isMobileNoValid) {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `https://api.zmpolymers.com/api/v1/sendEmail`,
          inputFields
        );
        setLoading(false);
        setIsSubmitted(false);
        clearFormValues();
        toast.success(data.message);
      } catch (err) {
        setLoading(false);
        // toast.error(
        //   err.response && err.response.data.message
        //     ? err.response.data.message
        //     : err.message
        // );
      }
    } else {
    }
  };

  const clearFormValues = () => {
    setInputFields({
      firstname: "",
      lastname: "",
      email: "",
      subject: "Aluminium",
      city: "",
      mobileNo: "",
      message: "",
    });
  };
  return (
    <>
      <Box className={classes.contactSec}>
        <Box id="Contact" className="section-container page-width">
          <Typography className={classes.contactUsTitle}>
            Get In Touch
          </Typography>
          <Grid container className={classes.mainContainer}>
            <Grid xs={12} lg={6}>
              <Box className={classes.mainBox}>
                <Box>
                  <iframe
                    className="gmap_iframe"
                    frameBorder="0"
                    aspact-ratio="1/0.8"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=22%C2%B010'03.4%22N%2070%C2%B050'29.2%22E+(ZM%20Polymers)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </Box>
                <Box className={classes.ofcBox}>
                  <Typography className={classes.ofcTitle}>
                    Contact Details
                  </Typography>

                  <Box className={classes.addressBox}>
                    <Box className={classes.address}>
                      {<LocationOnIcon className={classes.locationIcn} />}
                      <Flip top>
                        <Typography variant="body1" className={classes.addtxt}>
                          Kothariya Ring Road, Nr Kamnath Way Bridge, New
                          Nehrunagar Industrial Area,
                          <Typography>Rajkot - 360003</Typography>
                        </Typography>
                      </Flip>
                    </Box>
                    <Box className={classes.contactDetails}>
                      <Box className={classes.address}>
                        {<EmailIcon className={classes.locationIcn} />}
                        <Flip top>
                          <Typography
                            variant="body1"
                            className={classes.addtxt}
                          >
                            z.m.polymers66@gmail.com{" "}
                          </Typography>
                        </Flip>
                      </Box>
                      <Box className={classes.address}>
                        {<CallIcon className={classes.locationIcn} />}
                        <Typography variant="body1" className={classes.addtxt}>
                          +91 97380 34444{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.address}>
                      {<AccessTimeIcon className={classes.locationIcn} />}
                      <Flip top>
                        <Typography variant="body1" className={classes.addtxt}>
                          Monday to Saturday <br></br>10:00 AM to 08:00 PM{" "}
                        </Typography>
                      </Flip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={classes.mainBox}>
                <ToastContainer position="bottom-center" limit={1} />

                <form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          id="firstname"
                          name="firstname"
                          value={inputFields.firstname}
                          placeholder="Enter First Name"
                          helperText={
                            isFirstNameValid ? " " : "Please Fill First Name"
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          id="lastname"
                          name="lastname"
                          placeholder="Enter Last Name"
                          value={inputFields.lastname}
                          helperText={
                            isLastNameValid ? " " : "Please Fill First Name"
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField} error>
                        <Select
                          id="subject"
                          name="subject"
                          fullWidth
                          value={inputFields.subject}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        >
                          <MenuItem value="PVC Rubber">PVC Rubber</MenuItem>
                          <MenuItem value="Screw">Screw</MenuItem>
                          <MenuItem value="Aluminium">Aluminium</MenuItem>
                        </Select>
                        <FormHelperText>&nbsp;</FormHelperText>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          id="city"
                          name="city"
                          placeholder="Enter Your City"
                          value={inputFields.city}
                          helperText={" "}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          type="text"
                          id="mobileNo"
                          name="mobileNo"
                          placeholder="Enter Mobile Number"
                          value={inputFields.mobileNo}
                          inputProps={{ maxLength: 10 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            !isMobileNoValid
                              ? inputFields.mobileNo.length === 0
                                ? "Please Fill Mobile Number"
                                : inputFields.mobileNo.length < 10
                                ? "Please Fill Valid Mobile Number"
                                : " "
                              : " "
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Email"
                          value={inputFields.email}
                          helperText={" "}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.inputField}>
                        <TextField
                          fullWidth
                          rows={2}
                          multiline
                          id="message"
                          name="message"
                          placeholder="Enter Your Message"
                          value={inputFields.message}
                          helperText={" "}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className={classes.submitBtn}>
                    <Button
                      className={classes.btn}
                      onClick={(e) => submitHandler(e)}
                    >
                      {loading ? "Sending..." : "Submit"}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
            {/* Address  */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
