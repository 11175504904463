import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route

} from "react-router-dom";
import DashboardLayout from './DashboardLayout';
import Dashboard from './Pages/Dashboard';

export default function Router() {
  return (
    <BrowserRouter>
    <Routes>
    
    <Route element={ <DashboardLayout />}>
            <Route exact path="/" element={<Dashboard />} />
        </Route>

    </Routes>
</BrowserRouter>
  )
}
