import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import Images from "../../images";
import useStyles from "./carousalStyle";


function Carousal() {
  const classes = useStyles();
  const [images] = useState(Images);
  const settings = {
    autoplaySpeed: 2000,
    autoplay: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Box id="Home" >
      <Slider {...settings}>
          {images.map((step, index) => (
              <img
                key={index}
                alt={step.title}
                className={classes.bannerImg}
                src={step.imgPath}
              />
          ))}
        </Slider>
      </Box>
    </>
  );
}

export default Carousal;
