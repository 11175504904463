import React from "react";
import AboutUs from "../Components/AboutUs/AboutUs";
import Carousal from "../Components/Carousal/Carousal";
import ContactUs from "../Components/ContactUs/ContactUs";
import Product from "../Components/Product/Product";

export default function Dashboard() {
  return (
    <>
      <Carousal />
      <Product />
      <AboutUs />
      <ContactUs />
    </>
  );
}
