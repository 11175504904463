import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    flexGrow: 1,
    backgroundColor: '#234576',
  },
  iconColor1: {
    color: "green",
    alignItems: "center",
  },
  iconColor2: {
    color: "blue",
    alignItems: "center",
  },
  iconColor3: {
    color: "red",
    alignItems: "center",
  },
  iconBackColor: {
    backgroundColor:'#fff !important', 
    boxShadow: "0px 4px 4px rgb(0 0 0 / 50%)",
    width: "35px",
    height: "35px",
    display: " flex",
    borderRadius: "100%",
    alignItems: "center",
    justifyContent: "center",
    " & img": {
      width: "100%",
      maxWidth: " 25px",
    },
    "&:hover": {
      backgroundColor: '#D3D3D3ff !important',
      border: "1px solid #fff !important"
    }
  },
  copyRight: {
    fontWeight: "500 !important",
    color:"#fff",
    [theme.breakpoints.down(575)]: {
      fontSize: "13px !important",
    },
  },
  footerGrid: {
    [theme.breakpoints.down(900)]: {
      textAlign: "center",
      paddingTop:'13px'
    },
    [theme.breakpoints.down(575)]: {
      textAlign: "center",
      paddingTop:'13px'
    },
    paddingTop: 0,
    "& .css-78trlr-MuiButtonBase-root-MuiIconButton-root": {
      padding: {
        xs: "0 15px",
        md: "0 30px",
      },
    },
  },
  footerNavigation: {
    justifyContent: {
      xs: "center",
      sm: "flex-start",
    },
    "& p": {
      fontSize: {
        xs: "13px",
        sm: "16px",
      },
      paddingRight: {
        xs: "2px",
        sm: "8px",
        md: "15px",
      },
      // "15px",
    },
    "& a": {
      textDecoration: "none",
      color: "#000",
      "&::after": {
        content: "''",
        marginLeft: {
          xs: "4px",
          sm: "11px",
          md: "15px",
        },
        display: "inline-block",
        width: "1px",
        height: "14px",
        backgroundColor: "#000",
      },
    },
    "& #lastChild": {
      "&::after": {
        display: "none",
      },
    },
  },
  footerContent: {
    alignItems: "center",
  },
}));

export default useStyles;
