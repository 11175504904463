import * as React from "react";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
  Drawer,
  ListItemIcon,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// import PhoneIcon from "@mui/icons-material/Phone";
// import MailIcon from "@mui/icons-material/Mail";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useclasses from "./HeaderStyle";
import clsx from "clsx";

export default function FinalHeader(props) {
  const classes = useclasses();
  const drawerWidth = 240;
  const navItems = ["Home", "Products", "About", "Contact", "E-Catalogue"];
  const { window: myWindow } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activePage, setActivePage] = React.useState("Home");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const setActiveHeader = (sectionName) => {
    setActivePage(sectionName);
  }

  const scrollThis = (item,type) => {
     var element = document.getElementById(item)
     if(element) {
       element.scrollIntoView({behavior:"smooth"})
     }

     if(item === "E-Catalogue") {
        // window.location = "/docs/SM_Extrusion_Catalogue.pdf";
        window.open('/docs/SM_Extrusion_Catalogue.pdf', '_blank')
     }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} className={classes.drawerSection}>
      <IconButton className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Divider />
      <List className={classes.mobileMenuList}>
        {navItems.map((item) => (
          <ListItem key={item}>
            <ListItemButton
              sx={{ paddingLeft: 2 }}
              component="a"
              //onClick={() => scrollThis(item)}
              href={
                item === "E-Catalogue"? "/docs/SM_Extrusion_Catalogue.pdf":item==="Home"?"#":`#${item}`
              }
              key={item}
            >
              <ListItemText primary={item === "About" ? "About US" : item === "Contact" ? "Contact US" : item}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <Stack direction="row" className={classes.headerTopLeftSection}>
        <ListItem disablePadding component="a" href="https://wa.me/9738034444">
          <ListItemButton sx={{ paddingLeft: 2 }} className={classes.iconBackColor}>
            <ListItemIcon className={classes.socialMedia}>
            <img src='/social/whatsapp.png' alt="ZM WhatsApp Number Social Media Icon"/>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          component="a"
          href="mailto:z.m.polymers66@gmail.com"
        >
          <ListItemButton className={classes.iconBackColor}>
            <ListItemIcon className={classes.socialMedia}>
            <img src='/social/gmail.png' alt="ZM Email Social Media Icon"/>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding component="a" href="tel:9738034444">
          <ListItemButton className={classes.iconBackColor}>
            <ListItemIcon className={classes.socialMedia}>
            <img src='/social/telephoneCall.png' alt="ZM Calling Number Social Media Icon"/>
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </Stack>
    </Box>
  );

  const container =
  myWindow !== undefined ? () => myWindow().document.body : undefined;

  return (
    <>
      <AppBar component="nav" className={classes.headerBG}>
        <Toolbar
          className={clsx(classes.headerSection,"page-width")}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon className={classes.menuIconColor} />
          </IconButton>

            <Box className={classes.desktopLogo}>
              <a href="#Home" className={classes.desktopBox}>
                <img src="/ZM_Logo.png" alt="ZM_LOGO" />
              </a>
            </Box>

          <Box
            sx={{ display: { xs: "none", md: "block" }, alignSelf: "center" }}
          >
            {navItems.map((item) => (
              <Button
                key={item}
                className={classes.menuList}
                style={activePage === item ? {color:'#234576',textDecoration:'underline',textUnderlineOffset: '10px',textDecorationThickness: '2px'} : {color:'#000'}}
                onClick={() => {setActiveHeader(item);scrollThis(item)}}
                component="a"
              >
                {item === "About" ? "About US" : item === "Contact" ? "Contact US" : item}
              </Button>
            ))}
          </Box>
          <Box className={classes.mobileLogos}>
            <Box className={classes.mobileLogo}>
              <img src="/ZM_Logo.png" alt="ZM_LOGO"/>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          className={classes.drawer}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
