import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => (
  {
    /* #region HeaderTopBar */
    headerSection:{
      justifyContent:'space-between',
      width:'100%',
      [theme.breakpoints.down(1201)]: {
        padding: '0 !important',
      },
      [theme.breakpoints.down(900)]: {
        padding: '0 !important',
      }
    },
    headerTopBG: {
      width: '100%',
      zIndex: '99999',
      // position: 'fixed',
      flexGrow: '1',
      backgroundColor: '#234576',
      //top: '0',
      //height: '29px',
    },
    iconBackColor: {
      backgroundColor:'#fff !important', 
      boxShadow: "0px 4px 4px rgb(0 0 0 / 50%)",
      width: "20px",
      height: "50px",
      display: " flex",
      borderRadius: "100%",
      alignItems: "center",
      justifyContent: "center",
      " & img": {
        width: "100%",
        maxWidth: " 20px",
      },
      "&:hover": {
        backgroundColor: '#D3D3D3ff !important',
        border: "1px solid #fff !important"
      }
    },
    socialMedia:{
      justifyContent:'center'
    },
    headerItem: {
      "& .MuiListItemButton-root.MuiListItemButton-gutters": {
        padding: 0,
      },
    },
    mainGrid:{
        justifyContent:'space-between',
        [theme.breakpoints.down(1201)]: {
          padding: '0 30px'
        },
        [theme.breakpoints.down(900)]: {
          padding: '0 30px'
        }
    },
    listText: {
     
      flex:'0 auto !important',
      textDecoration: "none ",
      "& span": {
        fontSize: "14px ",
        color: "#fff",
      },
      "& .css-10hburv-MuiTypography-root":{
        fontWeight: "600 !important",
      }
    },
    leftlistIcons: {
      justifyContent: "start",
      minWidth: "30px !important ",
      "& svg": {
        fill: "#fff",
        height: "17px ",
        width: "17px",
      },
    },
    /* #endregion HeaderTopBar */

    /* #region Header */
    drawerSection: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    closeButton: {
      justifyContent: "right !important"
    },
    mobileMenuList: {
      flexGrow: 1,
      "& .MuiListItemText-root span": {
        fontWeight: "500",
        textTransform: "uppercase",
      }
    },
    
    headerTopLeftSection: {
      "& .MuiListItem-root .MuiListItemButton-root.MuiListItemButton-gutters": {
        paddingRight: 0
      }
    },

    headerBG: {
      backgroundColor: "#fff !important",
      position: 'sticky !important',
      // top:'29px !important',
      [theme.breakpoints.down(1201)]: {
        padding: '0 30px'
      },
      [theme.breakpoints.down(900)]: {
        top: '0 !important',
        padding: '0 30px'
      }
    
    },
    mobileLogos: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.up(900)]: {
        display: "none !important"
      }

    },
    desktopBox: {
      alignItems:'center !important',
      display:'flex !important',
    },
    mobileLogo: {
      alignItems:'center',
      display:'flex',
      maxWidth: "100px",
      "& img": {
        width: "100% ",
        padding: "10px 0",
      },
      [theme.breakpoints.up(993)]: {
        display: "none"
      },
      [theme.breakpoints.down(575)]: {
        "& img": {
          width: "75% !important",
        },
      }
    },
    desktopLogo: {
      
      maxWidth: "100px",
      "& img": {
        width: "100%",
        padding: "10px 0",
      },
      [theme.breakpoints.down(900)]: {
        display: "none"
      }
    },
    menuIconColor: {
      color: "#4b4c4d",
    },
    menuList: {
      fontWeight: "700 !important",
      my: 2,
      // color: "#000 !important",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    drawer: {
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
      },
      [theme.breakpoints.down(992)]: {
        display: "block "
      },
      [theme.breakpoints.up(993)]: {
        display: "none"
      }
    }
    /* #endregion Header */

  }
));

export default useStyles;
