import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  productTitle: {
    color:'#26497C',
    marginBottom: "10px !important",
    fontSize: "40px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down(576)]: {
      marginBottom: "0 !important",
      fontSize: "35px !important",
    },
  },
  maps: {
    display: "flex",
  },
  images: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "1200px",
    width: "100%",
  },
 productbtntxt:{
  fontWeight: "600 !important",
 },
  productbtn: {
     border: "1px solid #234576 !important",
    borderRadius:'25px !important',
    minWidth:'90px !important',
    padding: '8px 15px !important',
    color: "#fff !important",
    // backgroundColor: "#26497C !important",
    margin: "15px 0 0 auto",
    "&:hover": {
      color: "#234576 !important",
      backgroundColor: "#fff !important",
    },
    "&.active": {
      color: "#234576",
      border: "1px solid #234576"
    },
    [theme.breakpoints.down(576)]: {
      padding: '5px 9px !important',
    },
   
  },
'& .css-ahj2mt-MuiTypography-root':{
  [theme.breakpoints.down(576)]: {
    fontSize:'15px !important',
  },
},
imageBox:{
  height: "auto",
  display: "grid",
  gap: "15px",
  maxWidth: "1200px",
  margin: "0 auto",
 
  [theme.breakpoints.down(576)]: {
    gap:'0px',
  },
},
  productName:{
    background: 'rgb(35 69 118 / 80%) !important',
    "& .css-dasnyc-MuiImageListItemBar-title": {
      fontWeight:'600 !important'
    },
  },
  imageMenu: {
    margin: '10px 10px',
    borderRadius:'15px !important',
    overflow: "hidden",
  },
  imgs: {
    height:'auto !important',
    objectFit: "unset !important",
    cursor: "pointer",
    overflow: "hidden",
    transition: "transform 600ms ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));

export default useStyles;
