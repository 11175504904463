import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: "#fff", //background color , button color 
            main: '#D7E5FA', //background color
        },
        secondary: {
            light: "#000", //text color 
            main: '#26497C',
            dark: '#26497C',
            hover: '#EC4846'
        },
    },
    // //defalut 
            // body1: {
            //     // color: "#000",
            //     fontSize: "16px",
            //     lineHeight:"24px",
            //     fontWeight:"600 !important",
            //     fontFamily: "'Poppins', sans-serif", 
            //   },
   

});

export default theme