import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // input: {
  //   "&::placeholder": {
  //     color: "#000 !important",
  //   },
  // },
  sectionBGColor: {
    //backgroundColor: `${theme.palette.primary.light} !important`,
    display: "flex",
  },
  mainContainer: {
    [theme.breakpoints.down(1200)]: {
      flexWrap: 'inherit !important'
    },
    [theme.breakpoints.down(768)]: {
      flexWrap: 'wrap !important'
    },
    [theme.breakpoints.down(576)]: {
      flexWrap: 'wrap !important'
    },
  },
  contactSec: {
    //backgroundColor:'#224474'
  },
  mainBox: {
    padding: "20px !important",
  },
  contactUsTitle: {
    color: "#26497C",
    textAlign: "center",
    marginBottom: "10px !important",
    fontSize: "40px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down(576)]: {
      marginBottom: "0 !important",
      fontSize: "30px !important",
    },
  },
  contactBox: {
    display: "flex",
  },
  contacTxt: {
    justifyContent: "center",
    display: "flex",
    marginTop: "20px",
  },
  contactForm: {
    display: "flex",
    margin: "16px 0",
  },
  content: {
    lineHeight: "30px",
    maxWidth: "80%",
    textAlign: "center",
    margin: " 0 auto",
    [theme.breakpoints.down(1200)]: {
      padding: "35px 0 !important",
    },
    [theme.breakpoints.down(992)]: {
      padding: "0 !important",
    },
    [theme.breakpoints.down(575)]: {
      padding: "0 !important",
    },
  },
  inputField: {
    width: "100%",
    marginBottom: 8,
    ' & p': {
      color: '#FF0000 !important',
    },
    '& .css-18m8r0v': {
      color: '#000 !important',
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      [theme.breakpoints.down(575)]: {
        padding: '11px 10px !important'
      },
    },
    '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      [theme.breakpoints.down(575)]: {
        padding: '11px 10px !important'
      },
    },
    '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
      [theme.breakpoints.down(575)]: {
        padding: '12px 10px !important'
      },
    },
  },
  submitBtn: {
    justifyContent: "center",
    display: "flex",
    marginTop: "5px",
  },
  locationIcn: {
    color: "#26497C",
  },

  MuiOutlinedInputNotchedOutline: {
    borderColor: "#26497C !important",
  },

  btn: {
    border: "1px solid #234576 !important",
    borderRadius: "25px !important",
    minWidth: "90px !important",
    padding: "8px 30px !important",
    color: "#fff !important",
    fontWeight: '700  !important',
    fontSize: "18px !important",
    backgroundColor: "#26497C !important",
    "&:hover": {
      color: "#26497C !important",
      backgroundColor: "#fff !important",

    },
  },
  address: {
    gap: '12px',
    display: "flex",
  },
  addtxt: {
    textAlign: 'initial',
    color: "#26497C",
    lineHeight: "28px",
    maxWidth: "400px",
    fontSize: '17px !important',
    [theme.breakpoints.down(767.5)]: {
      maxWidth: '100%',
    },
  },
  ofcBox: {
    paddingTop: '25px'
  },
  addressBox: {
    textAlign: "center",
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  addresstxt: {
    color: "#26497C",
    display: "flex",
    justifyContent: "center",
  },
  addresstext: {
    color: "#26497C",
    maxWidth: "175px",
  },
  contactDetails: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column'
  },

  ofcTitle: {
    // marginBottom: '20px !important',
    // position: 'relative',
    // borderBottom: '2px solid rgb(167 144 144 / 35%)',
    // paddingBottom: '10px',
    color: "#26497C",
    padding: "10px 7px",
    fontSize: "30px !important",
    fontWeight: "700 !important",

    // '&:before':{
    //   bordersizing: 'borderBox',
    //   content: " ",
    //   position: 'absolute',
    //   bottom: '-2px',
    //   left: '0',
    //   width: ' 50px',
    //   height: ' 2px',
    //   backgroundColor: '#000'
    // },  
    [theme.breakpoints.down(767.5)]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down(575)]: {
      textAlign: 'center',
      marginBottom: "0 !important",
      fontSize: "23px !important",
    },
   
  },
}));

export default useStyles;
