const AllMenu = [
  {
    img: "./images/Products/PVC_Rubber/PVC_U-Rubber.webp",
    title: "PVC U-Rubber",
    category: "pvc"
  },
  {
    img: "./images/Products/PVC_Rubber/PVC_Rubber_Profile.webp",
    title: "PVC Rubber Profile",
    category: "pvc"
  },
  {
    img: "./images/Products/PVC_Rubber/PVC_Size_Table.webp",
    title: "PVC Size Table",
    category: "pvc"
  },
  {
    img: "./images/Products/Screw/Easy_Grip_Screw.webp",
    title: "Easy Grip Screw",
    category: "screw"
  },
  {
    img: "./images/Products/Screw/MS_Self_Tapping_Screw.webp",
    title: "MS Self Tapping Screw",
    category: "screw"
  },
  {
    img: "./images/Products/Screw/DryWall_Screw.webp",
    title: "DryWall Screw",
    category: "screw"
  },
  {
    img: "./images/Products/Aluminium/Aluminium_Reactangle_Tube.webp",
    title: "Aluminium Reactangle Tube",
    category: "aluminium"
  },
  {
    img: "./images/Products/Aluminium/Aluminium_Angles_and_Bars.webp",
    title: "Aluminium Angles & Bars",
    category: "aluminium"
  },
  {
    img: "./images/Products/Aluminium/Aluminium_Extrusions.webp",
    title: "Aluminium Extrusions",
    category: "aluminium"
  },
  {
    img: "./images/Products/Aluminium/Aluminium_Profiles.webp",
    title: "Aluminium Profiles",
    category: "aluminium"
  },
];

export default AllMenu;
