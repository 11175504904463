import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => (
  {
    bannerImg: {
      height: "100%",
      width: "100%",
      // maxHeight:' 825px'
    },
  }
));

export default useStyles;