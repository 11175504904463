import { makeStyles } from "@material-ui/core";
import abg from './aboutBg.webp'

const useStyles = makeStyles((theme) => (
  {
    AboutUSSection: {
      //margin: "20px 0",
    },
    bgColor:{
      position:'relative',
      backgroundImage: `url(${abg})`,
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
      backgroundPosition:'bottom'
      // margin: 'auto',
    },
    alltxt:{
      fontWeight: "600 !important",
      textAlign: 'justify',
      fontSize:'18px !important',
      color:'#fff',
      [theme.breakpoints.down(1200)]: {
        fontSize:'16px !important',
      },
      [theme.breakpoints.down(768)]: {
        fontSize:'15px !important',
      }
    },
   overlay:{
    backgroundColor: 'rgb(35 69 118 / 60%)!important',
   },
    heading: {
      color:'#fff',
      textAlign: "center",
      marginBottom: "10px !important",
      fontSize: "40px !important",
      fontWeight: "700 !important",
      [theme.breakpoints.down(575)]: {
        marginBottom: "25px !important",
        fontSize: "30px !important",
      }
    },

    borderDesign: {
      border: "1px solid #ccc",
      position: "relative",
      [theme.breakpoints.up(600)]: {
        "&.rightLogo": {
          top: "-31px",
          maxWidth: "90px !important",
        },
        "&.leftLogo": {
          top: "-31px",
          maxWidth: "90px !important",
        },
      }
    },
    '& .css-1fyyp8j-MuiGrid-root': {
      [theme.breakpoints.down(1199)]: {
        maxWidth:'100% !important',
      },
    },
    allContent: {
    
     
      [theme.breakpoints.down(900)]: {
        padding: "20px 70px",
     },
     [theme.breakpoints.down(769)]: {
      padding: "20px 45px",
   },
   [theme.breakpoints.down(576)]: {
    padding: "10px 10px",
 },
    },
    rightSection: {
      marginTop: "100px"
    },
    readMoreBtn:{
      textAlign:'center',
    },
    leftLogo:{
      "& img": {
        width: "500px",
        height: "100%",
      },
      [theme.breakpoints.down(1199)]: {
        textAlign:'center',
        "& img": {
          width: "450px",
        },
     },
     [theme.breakpoints.down(1025)]: {
      textAlign:'center',
      "& img": {
        width: "400px",
      },
   },
      [theme.breakpoints.down(900)]: {
        textAlign:'center',
        "& img": {
          width: "350px",
        },
     },
     [theme.breakpoints.down(768.5)]: {
        textAlign:'center',
        "& img": {
          width: "400px",
        },
     },
      [theme.breakpoints.down(575)]: {
        "& img": {
          width: "300px",
        },
    },
  },
    readMore: {
      borderRadius:'25px !important',
      padding: "12px 20px !important",
      margin: "15px 0 0 auto !important",
      color: "#fff !important",
      backgroundColor: `#26497C !important`,
      "&:hover": {
        color: "#fff !important",
        backgroundColor: `#EC4846 !important`,
        border: "1px solid pink !important"
      }
    },
    gridSection: {
      justifyContent: 'space-between',
      padding: "40px 0",
      [theme.breakpoints.down(1201)]: {
        justifyContent: 'space-around',
        padding:'40px 0'
     },
  //    [theme.breakpoints.down(1025)]: {
  //     justifyContent: 'space-around',
  //     padding:'40px 0'
  //  },
      [theme.breakpoints.down(900)]: {
        justifyContent:'center',
        padding:'10px 0'
     },
    },
    hideContent: {
      height: "160px",
      overflow: "hidden",
    },
    showContent: {
      height: "auto",
    }
  }
));

export default useStyles;
