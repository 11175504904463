import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import useStyles from "./aboutUsStyle";
import Slide from "react-reveal/Slide";

export default function AboutUs() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.bgColor}>
        <Box className={classes.overlay}>
          <Box id="About" className="section-about page-width">
            <Typography className={classes.heading}>About Us</Typography>
            <Grid container className={classes.gridSection}>
              <Slide left>
                <Grid item xs={12} md={6} className={classes.leftLogo}>
                  <img src="/about.jpg" alt="ZM Polymers Factory About US Background Image" />
                </Grid>
              </Slide>
              <Grid item xs={12} md={6}>
                <Box className={classes.allContent}>
                  <Slide right>
                    <Box>
                      <Typography className={classes.alltxt}>
                        ZM Polymers began operations as a PVC extruder in
                        February, 2017 with the brand name "Perfect". 
                        We are engaged in the production of a wide range of
                        u-type PVC rubber, PVC profile, PVC beading, and PVC
                        glass gasket for aluminium doors and windows.
                        <br></br>
                        <br></br>
                        Through frequent client input, we continuously strive to
                        raise the quality standards of our products. 
                        We also guarantee prompt, effective product delivery. 
                        We also produce different window and door components. 
                        We take great pride in being one of India's top PVC brands.
                        <br></br>
                        <br></br>
                        We are additionally engaged in manufacturing of all type of Aluminium Extrusions.
                        We offer aluminium extrusion & profiles with the widest possible combination 
                        of alloy, temper and shapes for variety of industries,
                        such as door & windows, angel, flat bars, round pipes,
                        square tubes and in different shapes as well.
                        Our products are suitable for anodize, powder coating, wooden coating. 
                        We provide various type alloy ingots as well.
                        We are committed to providing our customers with superior products and excellent customer service.
                      </Typography>
                    </Box>
                  </Slide>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* </Parallax> */}
      </Box>
    </>
  );
}
