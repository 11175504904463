import React, { useState } from "react";
import Box from "@mui/material/Box";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AllMenu from "../../menu";
import useStyles from "./productStyle";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 600,
      tablet: 900,
      desktop: 1200,
    },
  },
});

export default function Product() {
  const classes = useStyles();
  const [items, setItems] = useState(AllMenu);
  const [isOpen, setIsOpen] = useState(false);
  const [isProductActive, setIsProductActive] = useState("All");

  const [imageState, setimageState] = useState("");

  const showModal = (item) => {
    setimageState(item);
    setIsOpen(true);
  };
  const filterItem = (categItem) => {
    const updateItems = AllMenu.filter((curEle) => {
      return curEle.category === categItem;
    });
    setIsProductActive(categItem);
    setItems(updateItems);
  };
  const setAllCategory = () => {
    setIsProductActive("All");
    setItems(AllMenu);
  };

  return (
    // <ThemeProvider theme={theme}>

    <Box id="Products" className="section-container page-width">
      <Box className={classes.images}>
        <Typography className={classes.productTitle}>Our Products</Typography>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          // margin: "0 auto",
          // maxWidth: "1200px",
          width: "100%",
          marginBottom: "20px",
          "& button": { m: 1 },
        }}
      >
        {/* <Stack direction="row" spacing={2} classeNam={{justifyContent:'center'}} > */}
        <Button
          className={classes.productbtn}
          size="medium"
          onClick={() => setAllCategory()}
          style={
            isProductActive === "All"
              ? {
                  color: "#234576",
                  backgroundColor: "#fff",
                  border: "1px solid #234576",
                }
              : { backgroundColor: "#26497C" }
          }
        >
          <Typography
            className={classes.productbtntxt}
            style={
              isProductActive === "All"
                ? {
                    color: "#234576",
                  }
                : {
                    // color: "#fff"
                  }
            }
          >
            All
          </Typography>
        </Button>

        <Button
          className={classes.productbtn}
          size="medium"
          onClick={() => filterItem("aluminium")}
          style={
            isProductActive === "aluminium"
              ? {
                  color: "#234576",
                  backgroundColor: "#fff",
                  border: "1px solid #234576",
                }
              : { backgroundColor: "#26497C" }
          }
        >
          <Typography
            className={classes.productbtntxt}
            style={
              isProductActive === "aluminium"
                ? {
                    color: "#234576",
                  }
                : {
                    //color: "#fff"
                  }
            }
          >
            Aluminium
          </Typography>
        </Button>
        <Button
          className={classes.productbtn}
          size="medium"
          onClick={() => filterItem("screw")}
          style={
            isProductActive === "screw"
              ? {
                  color: "#234576",
                  backgroundColor: "#fff ",
                  border: "1px solid #234576",
                }
              : { backgroundColor: "#26497C" }
          }
        >
          <Typography
            className={classes.productbtntxt}
            style={
              isProductActive === "screw"
                ? {
                    color: "#234576",
                  }
                : {
                    //color: "#fff"
                  }
            }
          >
            Screw
          </Typography>
        </Button>
        <Button
          className={classes.productbtn}
          size="medium"
          onClick={() => filterItem("pvc")}
          style={
            isProductActive === "pvc"
              ? {
                  color: "#234576",
                  backgroundColor: "#fff",
                  border: "1px solid #234576",
                }
              : { backgroundColor: "#26497C" }
          }
        >
          <Typography
            className={classes.productbtntxt}
            style={
              isProductActive === "pvc"
                ? {
                    color: "#234576",
                  }
                : {
                    //color: "#fff"
                  }
            }
          >
            PVC Rubber
          </Typography>
        </Button>

        {/* </Stack> */}
      </Box>
      <ThemeProvider theme={theme}>
        <Box
          className={classes.imageBox}
          sx={{
            gridTemplateColumns: {
              mobile: "repeat(1, 1fr)",
              bigMobile: "repeat(2, 1fr)",
              tablet: "repeat(3, 1fr)",
              desktop: "repeat(4, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {items?.map((item) => {
            return (
              <ImageListItem key={item.img} className={classes.imageMenu}>
                <img
                  onClick={(e) => showModal(item)}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  data-aos="zoom-in"
                  className={classes.imgs}
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                />

                <ImageListItemBar
                  title={item.title}
                  // position="below"
                  className={classes.productName}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.title}`}
                    ></IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </Box>
        {isOpen && (
          <Modal
            src={imageState.img}
            alt={imageState.title}
            //caption="caption"
            onClose={() => setIsOpen(false)}
          />
        )}
      </ThemeProvider>
    </Box>
  );
}

export const Modal = ({ src, alt, caption, onClose }) => {
  return (
    <div className="modal">
      <div className="custom-modal">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <img className="modal-content" src={src} alt={alt} />
      </div>
    </div>
  );
};
