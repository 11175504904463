import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Components/Footer/Footer'
import Header from './Components/Header/Header'
import HeaderTopBar from './Components/Header/HeaderTopBar'

export default function DashboardLayout(props) {
  return (
    <>
      <HeaderTopBar />
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
